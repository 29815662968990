import React, { useMemo } from 'react';
import dynamic from 'next/dynamic';

import TransferCom from '@/components/public/TransferCom';
import { PAGE_MOBILE_W } from '@/utils';

const PCFirstAdvertise = dynamic(() => import('./pc'), { ssr: false });
const MFirstAdvertise = dynamic(() => import('./m'), { ssr: false });
const SideFirstAdvertise = dynamic(() => import('./side'), { ssr: false });
import type { AdvertiseProps } from './type';

const FirstAdvertise: React.FC<AdvertiseProps> = (props) => {
    const { serviceData } = props;
    const { isRight } = serviceData || {};

    const isShowM = useMemo(() => {
        try {
            let width = 1024;
            if (typeof window !== 'undefined') {
                width = document.querySelector('body')?.clientWidth as number;
            }

            return width < PAGE_MOBILE_W;
        } catch {
            return false;
        }
    }, []);

    const pcDom = isRight ? (
        <SideFirstAdvertise {...props} moreStyle={{ width: 332, minHeight: 298 }} />
    ) : (
        <PCFirstAdvertise {...props} />
    );

    // 是否展示移动端
    if (isShowM) {
        return <MFirstAdvertise {...props} />;
    }

    return <TransferCom pc={pcDom} ipad={pcDom} m={<MFirstAdvertise {...props} />} />;
};

export default FirstAdvertise;
