import dayjs from 'dayjs';
import cookie from 'cookie';
import ResizeObserver from 'resize-observer-polyfill';
import { load as cheerioLoad } from 'cheerio';
import type { NextRouter } from 'next/router';
import intl from 'react-intl-universal';

import { defaultLocale } from '@/constants/mts';

export const PAGE_CENTER_NEW = 1376; // 新版心宽度
export const PAGE_CENTER_NORMAL = 1184; // 旧版心宽度
export const PAGE_MAIN_LEFT_W = 1028; // 内容左侧宽度
export const PAGE_MAIN_RIGHT_W = 332; // 内容右侧宽度
export const PAGE_MOBILE_W = 768; // 判断移动端的最大宽度
export const PAGE_CENTER_WA = 882; // 4列最小宽
export const PAGE_CENTER_WB = 680; // 3列最小款
export const PAGE_CENTER_WC = 415; // 2列最小宽

// 获取响应式antd row span
export const getColSpan = (domWidth: number, defaultsCulumns: number = 4) => {
    switch (defaultsCulumns) {
        case 4: // 默认一行四列
            if (domWidth > PAGE_CENTER_WA) {
                return 6;
            }
            if (domWidth > PAGE_CENTER_WB) {
                return 8;
            }
            if (domWidth > PAGE_CENTER_WC) {
                return 12;
            }
            return 24;
        case 3: // 默认一行三列
            if (domWidth > PAGE_CENTER_WB) {
                return 8;
            }
            if (domWidth > PAGE_CENTER_WC) {
                return 12;
            }
            return 24;
        default:
            return 6;
    }
};

// 通过key获取url中的参数值
export const getQueryString = (n: string) => {
    const reg = new RegExp('(^|&)' + n + '=([^&]*)(&|$)', 'i');
    const r = window.location.search.substring(1).match(reg);
    if (r != null) return decodeURIComponent(r[2]);
    return null;
};

// 获取search字段值
export const getSearchString = (n: string, h?: string) => {
    const search = (h || window.location.search).split('?')[1];
    if (!search) {
        return null;
    }
    const reg = new RegExp('(^|&)' + n + '=([^&]*)(&|$)');
    const r = search.match(reg);
    if (r != null) {
        return decodeURI(r[2]);
    }
    return null;
};

// 获取search字符串
export const getQuerySearch = (h: string) => {
    return (h || window.location.search).split('?')[1];
};

// 去除对象空值
export const filters = (o: any) => {
    if (Object.keys(o).length) {
        const obj: any = {};
        for (const k in o) {
            const v = o[k];
            if (v != null) {
                if (v.constructor === Object) {
                    obj[k] = filters(v);
                } else {
                    if ((v.constructor === Array && v.length) || (!/^\s*$/.test(v) && v !== undefined && v !== null)) {
                        obj[k] = o[k];
                    }
                }
            }
        }
        return obj;
    } else {
        return {};
    }
};

// 单位处理
export const formatDomUnit = (o: any, lw: number = PAGE_CENTER_WB) => {
    const propUnits = ['width', 'height', 'paddingTop', 'paddingRight', 'paddingBottom', 'paddingLeft'];
    const obj: any = {};
    Object.keys(o).forEach((e: any) => {
        if (e.includes('Unit')) {
            return;
        }
        obj[e] = o[e];
        let num = o[e];

        if (o[`${e}Unit`] === 'px' && o[e] !== 0) {
            num = (o[e] * lw) / PAGE_CENTER_WB;
        }
        if (propUnits.includes(e)) {
            obj[e] = num ? `${num}${o[e + 'Unit'] || 'px'}` : null;
        }
    });
    return obj;
};

// 文章阅读数 当阅读量大于9999时，显示为1w，大于1w时，显示至小数点后一位，例如1w人看过、1.2w人看过、2.8w人看过
export const getArticleViewNum = (v: any, unit?: string) => {
    const num = Number(v || 0);
    if (num < 10000) {
        return num;
    } else {
        const n = num / 10000;
        return `${n.toFixed(2)}${unit || 'W'}`;
    }
};

export const getHtmlPlainText = (html_str: string) => {
    // 提取字符串中的文字
    const re = /<[^<>]+>/g;
    const text = html_str.replace(re, '');
    return text;
};

// 格式化置顶文章
export const formatIsTopList = (l: any[]) => {
    const restList: any = [];
    let tempList: any = [];
    l.forEach((e: any, i: number) => {
        tempList.push(e);
        if (e.newline) {
            restList.push({ key: i, data: tempList });
            tempList = [];
            return;
        }

        if (i === l.length - 1) {
            restList.push({ key: i, data: tempList });
            tempList = [];
        }
    });
    return restList;
};

// 视频处理格式 将数组分割成每n个一组
export const formatVideoList = (l: any[], n: number) => {
    const len = n - ((l || []).length % n);
    const list = Array.from({ length: len !== 3 ? len : 0 }, (_v: any, i: number) => ({ key: i }));
    const forList = l.concat(list);
    const newList = []; // 首先创建一个新的空数组。用来存放分割好的数组
    for (let i = 0; i < forList.length; ) {
        // 注意：这里与for循环不太一样的是，没有i++
        newList.push(forList.slice(i, (i += n)));
    }
    return newList;
};

export const filterSpace = (str: string) => {
    return str.trim().replace(/&nbsp;/g, ''); // js去掉两头空格
};

// 格式时间线文章
export const formaTimerLetter = (l: any[]) => {
    const list = l || [];
    const parentTimer: any = {};
    list.forEach((e: any) => {
        const { datetime } = e;
        // const date = new Date(datetime)
        const fdate = dayjs(datetime).format('YYYY/MM/DD');
        const timer = new Date(fdate).getTime();

        if (parentTimer[timer]) {
            parentTimer[timer].push(e);
        } else {
            parentTimer[timer] = [e];
        }
    });
    return parentTimer;
};

// 格式时间线文章
export const formatNewsLetter = (l: any[], n: number = 1) => {
    const list = l || [];
    const parentTimer: any = {};
    list.forEach((e: any) => {
        const { datetime } = e;
        const date = new Date(datetime * n);
        const fdate = dayjs(date).format('YYYY-MM-DD');
        const timer = new Date(fdate).getTime();

        if (parentTimer[timer]) {
            parentTimer[timer].push(e);
        } else {
            parentTimer[timer] = [e];
        }
    });
    return parentTimer;
};

// 获取字符串中文和非中文个数
export const getByteLen = (v: string) => {
    let zlen = 0;
    let elen = 0;
    for (let i = 0; i < v.length; i++) {
        const length = v.charCodeAt(i);
        if (length >= 0 && length <= 128) {
            elen += 1;
        } else {
            zlen += 1;
        }
    }
    return { zlen, elen };
};

// 时间处理
export const formatTimer = (v: any) => {
    const date = new Date(v).getTime();
    const currentDate = new Date().getTime();
    const spaceTime: number = Math.abs(currentDate - date) / 1000; // 把相差的毫秒数转换为秒数
    if (spaceTime < 60) {
        // 间隔时间小于1分钟，返回分钟数
        return intl.get('Article.Just').d('刚刚');
    }
    if (spaceTime < 3600) {
        // 间隔时间小于1小时，并且上面的条件都达不到， 返回分钟数
        const time = Math.floor(spaceTime / 60);
        return time + intl.get('Article.MinutesAgo').d('分钟前');
    } else if (spaceTime < 86400) {
        // 间隔时间小于1天，并且上面的条件都达不到，返回小时数
        const time = Math.floor(spaceTime / 60 / 60);
        return time + intl.get('Article.HoursAgo').d('小时前');
    } else if (spaceTime < 172800) {
        // 间隔时间小于2天，并且上面的条件都达不到，返回昨天
        // const time = Math.floor(spaceTime / 60 / 60 / 24);
        return intl.get('Article.OneDay').d('1天前');
    } else {
        const diffYear = dayjs().year() - dayjs(v).year();
        let endTimeRule = 'MM-DD';
        // 跨年增加展示年
        if (diffYear > 0) {
            endTimeRule = 'YYYY-MM-DD';
        }
        // 间隔时间大于2天，并且上面的条件都达不到，返回天数
        // 超出4天不显示发布时间字段
        const time = dayjs(v).format(endTimeRule);
        return time;
    }
};

// 新时间处理，重构后的时间规则
export const newFormatTimer = (v: any) => {
    const date = new Date(v).getTime();
    const currentDate = new Date().getTime();
    const spaceTime: number = Math.abs(currentDate - date) / 1000; // 把相差的毫秒数转换为秒数
    if (spaceTime < 600) {
        // 间隔时间小于10分钟，返回刚刚
        return intl.get('Article.Just').d('刚刚');
    }
    if (spaceTime < 3600) {
        // 间隔时间小于1小时，大于10分钟， 返回分钟数
        const time = Math.floor(spaceTime / 60);
        return time + intl.get('Article.MinutesAgo').d('分钟前');
    } else if (spaceTime < 86400) {
        // 间隔时间小于1天，大于1小时， 返回小时数
        const time = Math.floor(spaceTime / 60 / 60);
        return time + intl.get('Article.HoursAgo').d('小时前');
    } else if (spaceTime < 86400 * 2) {
        // 间隔时间小于2天，大于1天， 返回1天前
        return intl.get('Article.OneDay').d('1天前');
    } else if (spaceTime < 86400 * 3) {
        // 间隔时间小于3天，大于2天， 返回2天前
        return intl.get('Article.TwoDay').d('2天前');
    } else if (spaceTime < 86400 * 4) {
        // 间隔时间小于4天，大于3天， 返回3天前
        return intl.get('Article.ThreeDay').d('3天前');
    } else {
        // 超出4天不显示发布时间字段
        return '';
    }
};

// storage
export const getStorage = (key: string, parse = true) => {
    const v = localStorage.getItem(key);
    // console.log('vvvvvvvvvvvvvvvvvvv',v,parse)
    try {
        if (v) {
            // console.log('*********************',JSON.parse(v))
            return parse ? JSON.parse(v) : v;
        }
    } catch (error) {
        // console.log('errr',error)
        return null;
    }
    return null;
};
export const setStorage = (key: string, value: any, parse = true) => {
    let v: any = value;
    if (parse) {
        v = JSON.stringify(value);
    }
    localStorage.setItem(key, v);
};
export const removeStorage = (key: string) => {
    localStorage.removeItem(key);
};

/**
 * cookie
 * @param req
 * @returns
 */
export const parseCookies = (req: any) => {
    return cookie.parse(req ? req?.headers?.cookie || '' : document?.cookie);
};

/**
 * service请求报错，重定向到error页面 url 处理
 * @param url
 * @param locale
 * @returns
 */
export const redirectUrl = (url: string, locale?: string) => {
    const currentLocale = locale || intl.getInitOptions().currentLocale;

    if (currentLocale !== defaultLocale) {
        return `/${currentLocale}${url}`;
    }

    return url;
};

// service请求报错，重定向到error页面
export const redirectFun = (res: any, redirect?: string, locale?: string) => {
    const { traceId, msg, state } = res;
    // const str = (traceId ? `traceId=${traceId}` : '') + (msg ? `&msg=${encodeURIComponent(msgMts)}` : '');
    const str = (traceId ? `traceId=${traceId}` : '') + `&state=${state}`;
    switch (state) {
        case 10014: // 未登录
            setTimeout(() => {
                (window as any)?.onLogin?.();
            });
            return {
                // redirect: {
                //     destination: redirectUrl(
                //         redirect ? `/login?redirect=${encodeURIComponent(redirect)}` : '/login',
                //         locale,
                //     ),
                //     permanent: false,
                //     query: { redirect },
                // },
            };
        default:
            return {
                redirect: {
                    destination: redirectUrl(str ? `/error?${str}` : '/error', locale),
                    permanent: false,
                    query: { traceId, msg, state },
                },
            };
    }
};

export const getTagsLen = (tags: any[], num: number = 3, key?: string) => {
    if (key) {
        const index = (tags || []).findIndex((tag: string) => {
            return tag === key;
        });
        if (index > num - 1) {
            return [key, ...(tags || []).slice(0, num - 1)];
        } else {
            return (tags || []).slice(0, num);
        }
    } else {
        return (tags || []).slice(0, num);
    }
};

export const converWidth = (w: number, pw: number = 882, nw: number = 1184, t?: boolean) => {
    if (t) {
        return w;
    }
    const ratio = nw / pw;
    return Math.ceil(ratio * w);
};

// 动态修改可视化固定的 width
export const formatPageData: any = (l: any[], t: boolean = true) => {
    const propsList = ['width', 'height', 'paddingTop', 'paddingRight', 'paddingBottom', 'paddingLeft', 'borderRadius'];
    return (l || []).map((e: any) => {
        const { state, props = {}, children = [] } = e || {};
        const { customStyle = {} } = props || {};
        propsList.forEach((key: string) => {
            if (!(customStyle[key] || customStyle[key] === 0)) {
                customStyle[key] = undefined;
                customStyle[`${key}Unit`] = undefined;
            }
        });
        return filters({
            ...e,
            auth: t ? state === 1 : true,
            props: { ...props, customStyle: filters(customStyle) },
            children: formatPageData(children, t && state === 1),
            rules: undefined,
        });
    });
};

export function debounce(fn: (event: any) => void, delay: number) {
    // 定时器，用来 setTimeout
    let timer: any = null;

    // 返回一个函数，这个函数会在一个时间区间结束后的 delay 毫秒时执行 fn 函数
    return function () {
        // 保存函数调用时的上下文和参数，传递给 fn
        // @ts-ignore
        const context = this;
        const args: any = arguments;

        // 每次这个返回的函数被调用，就清除定时器，以保证不执行 fn
        clearTimeout(timer);

        // 当返回的函数被最后一次调用后（也就是用户停止了某个连续的操作），
        // 再过 delay 毫秒就执行 fn
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    };
}

// 监听元素宽度变化
export const resizeObserverFun = (dom: any, callback: Function, prop?: string) => {
    const resizeObserver = new ResizeObserver((eg: any = []) => {
        const _wh: number = eg[0]?.target[prop || 'clientWidth'];
        callback(_wh);
    });

    dom && resizeObserver.observe(dom);
    return () => {
        resizeObserver.disconnect();
    };
};

// 是否在微信浏览器
export const isWeixin = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.includes('micromessenger');
};

export const verifyPhone = (value: string) => {
    return !!value;
};

interface autoTS {
    (val: number): void;
    (arg0: string): void;
}
export const autoComputeMathematics = (
    verificationCode: string,
    handle: React.MutableRefObject<any>,
    onChange: autoTS,
    onLoading: autoTS,
) => {
    let verificationCodeNum = 60;
    const tempContext = verificationCode;
    handle.current = setInterval(() => {
        verificationCodeNum--;
        if (verificationCodeNum < 1) {
            onChange(tempContext);
            clearInterval(handle.current);
        } else {
            onLoading(verificationCodeNum);
        }
    }, 1000);
};

// 随机数字
export const getRandomInt = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

/**
 * 处理快讯原文联调跳转的url
 * @param code 原文类型 0 无| 1 内联| 2外链
 * @param id 文章id信息 {文章code}|{文章id}
 * @param url 外链url
 * @returns 需要跳转的url
 */
export const sourceUrlHandler = (code: number, id: string, url: string) => {
    let sourceLink = '';
    switch (code) {
        case 1:
            const articleCode = id?.split?.('|')?.[0] || '';
            sourceLink = `/article/${articleCode}`;
            break;
        case 2:
            sourceLink = url;
            break;
    }
    return sourceLink;
};

// 随机uuid
export const createUUID = (len: number, radix: number) => {
    const chars = '0123456789'.split('');
    const uuid = [];
    let i;
    radix = radix || chars.length;
    if (len) {
        for (i = 0; i < len; i++) {
            uuid[i] = chars[0 | (Math.random() * radix)];
        }
    }
    return '-' + uuid.join('');
};

// 交易商年份
export const formatFiveYear = (v: any) => {
    const num = dayjs().diff(dayjs(v), 'year', true);
    const count = Math.floor(num / 5);
    if (count < 1) {
        return intl.get('Broker.VithinFiveYear').d('5年内');
    }
    return intl.get('Broker.MoreYear', { field: count * 5 }).d(`${count * 5}年以上`);
};

/*
 * 根据文件名的尾缀 返回文件类型
 * @param {any} fileName 文件名
 */
export function getFileType(fileName: string) {
    // 后缀获取
    let suffix = '';
    // 获取类型结果
    let result: any = '';
    try {
        const flieArr = fileName.split('.');
        suffix = flieArr[flieArr.length - 1];
    } catch (err) {
        suffix = '';
    }
    // fileName无后缀返回 false
    if (!suffix) {
        return false;
    }
    suffix = suffix.toLocaleLowerCase();
    // 图片格式
    const imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
    // 进行图片匹配
    result = imglist.find((item) => item === suffix);
    if (result) {
        return 'image';
    }
    // 匹配txt
    const txtlist = ['txt'];
    result = txtlist.find((item) => item === suffix);
    if (result) {
        return 'txt';
    }
    // 匹配 excel
    const excelist = ['xls', 'xlsx'];
    result = excelist.find((item) => item === suffix);
    if (result) {
        return 'excel';
    }
    // 匹配 word
    const wordlist = ['doc', 'docx'];
    result = wordlist.find((item) => item === suffix);
    if (result) {
        return 'word';
    }
    // 匹配 pdf
    const pdflist = ['pdf'];
    result = pdflist.find((item) => item === suffix);
    if (result) {
        return 'pdf';
    }
    // 匹配 ppt
    const pptlist = ['ppt', 'pptx'];
    result = pptlist.find((item) => item === suffix);
    if (result) {
        return 'ppt';
    }
    // 匹配 视频
    const videolist = ['mp4', 'm2v', 'mkv', 'rmvb', 'wmv', 'avi', 'flv', 'mov', 'm4v'];
    result = videolist.find((item) => item === suffix);
    if (result) {
        return 'video';
    }
    // 匹配 音频
    const radiolist = ['mp3', 'wav', 'wmv'];
    result = radiolist.find((item) => item === suffix);
    if (result) {
        return 'radio';
    }
    // 其他 文件类型
    return 'other';
}

/**
 * 检查两个url地址的一级域名是否相同 http only
 */
const getUrlParmas = (url: string) => {
    try {
        const dom = new URL(url || '');
        return dom?.host;
    } catch {
        return null;
    }
};
export const CheckTheSameHost = (url1_domain: string, url2: string) => {
    const url2_domain = getUrlParmas(url2);
    if (!url2_domain) {
        return false;
    }
    return url1_domain !== url2_domain;
};

// url
export const formatIsUrl = (v: string) => {
    const reg = new RegExp(/(http|https):\/\/([\w.]+\/?)\S*/i);
    return reg.test(v);
};

// 拦截 a 标签跳转
const choosePush = (element: any) => {
    const urlHref = element.getAttribute('href');
    const target = element.getAttribute('target') || '_self';
    const type = element.getAttribute('data-type');
    const { host } = window?.location || {};

    if (!urlHref) {
        // 防止无 href 的 a 标签被拦截
        return;
    }
    if (CheckTheSameHost(host, urlHref) && type !== 'file') {
        window.open(currentLocaleUrl(`/link?target=${encodeURIComponent(urlHref)}`), '_blank');
    } else {
        window.open(currentLocaleUrl(urlHref), target);
    }
};

export const addHrefEventListener = () => {
    const aTags = document.querySelectorAll('a');
    for (let i = 0; i < aTags.length; i++) {
        aTags[i].onclick = (event: any) => {
            const target = aTags[i];
            if (event.preventDefault) {
                // 对捕获到的 a 标签进行处理
                event.preventDefault();
            } else {
                window?.event?.preventDefault();
            }
            choosePush(target);
        };
    }
};

/**
 * 查询设备是否为PC。
 * @returns {boolean}
 */
export const IsPC = () => {
    try {
        const userAgentInfo = navigator?.userAgent;
        const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
        let flag = true;
        for (const item of Agents) {
            if (userAgentInfo.indexOf(item) > 0) {
                flag = false;
                break;
            }
        }
        return flag;
    } catch (e: any) {
        console.error(e);
        return false;
    }
};

/**
 * 查询设备是否为Android。如果返回true 则说明是Android  false是ios
 * @returns {boolean}
 */
export const IsAndroid = () => {
    try {
        const u = navigator?.userAgent;
        const isAndroid = u?.indexOf('Android') > -1 || u.includes('Linux'); // g
        const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
        if (isAndroid) {
            // 这个是安卓操作系统
            return true;
        }
        if (isIOS) {
            // 这个是ios操作系统
            return false;
        }
    } catch (e: any) {
        console.error(e);
        return false;
    }
};

/**
 * 获取图片的 base64 编码
 * @param image 图像对象
 * @returns {string} 返回已编码的 base64数据
 */
export function getImageBase64(image: HTMLImageElement) {
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const ctx = canvas.getContext('2d');
    ctx?.drawImage(image, 0, 0, image.width, image.height);
    // 获取图片后缀名
    const extension = image.src.substring(image.src.lastIndexOf('.') + 1).toLowerCase();
    // 某些图片 url 可能没有后缀名，默认是 png
    return canvas.toDataURL('image/' + extension, 1);
}

// 判断字节长度
export const getBytesLength = (str: string) => {
    // 获取字符串的字节数
    let count = 0; // 初始化字节数递加变量并获取字符串参数的字符个数
    if (str) {
        // 如果存在字符串，则执行
        const len = str.length;
        for (let i = 0; i < len; i++) {
            // 遍历字符串，枚举每个字符
            if (str.charCodeAt(i) > 255) {
                // 字符编码大于255，说明是双字节字符(即是中文)
                count += 2; // 则累加2个
            } else {
                count++; // 否则递加一次
            }
        }
        return count; // 返回字节数
    } else {
        return 0; // 如果参数为空，则返回0个
    }
};

/** 格式化显示字数 */
export const validateShowCount = (args: { value: string; maxLength?: number | any }) => {
    const { value = '', maxLength } = args || {};
    const len = getBytesLength(value);
    const count = len !== 0 ? Math.floor(len / 2) : 0;
    return (count === 0 ? `0/${Math.floor(maxLength / 2)}` : `${count}/${Math.floor(maxLength / 2)}`).toString();
};

export const validateBytesZhLength = (byteLength: number) => {
    return (_: any, value: string) => {
        if (getBytesLength(value) > byteLength * 2) {
            const errMsg = intl
                .get('Broker.ByteLength', { field1: byteLength, field2: Math.floor(byteLength * 2) })
                .d(`最多输入${Math.floor(byteLength)}个中文字符或${Math.floor(byteLength * 2)}个英文字符`);
            return Promise.reject(errMsg);
        }
        return Promise.resolve();
    };
};

/** 跳转页面，做登录拦截，未登录先去登录页 */
export const loginAuthenticationToPage = (router: NextRouter, url: string) => {
    if (!localStorage.getItem('Authorization')) {
        (window as any)?.onLogin?.();
        // const { asPath, pathname } = router;
        // router.push(`/login?redirect=${encodeURIComponent(asPath || pathname)}`);
        window.antdMessage.error(intl.get('Common.PleaseLogInFirst').d('请先登录'));
        return;
    }
    window.open(currentLocaleUrl(url));
};

/** url多语言处理 */
export const currentLocaleUrl = (url: string, locale?: string) => {
    const currentLocale = locale || intl.getInitOptions().currentLocale;

    if (formatIsUrl(url)) {
        return url;
    }

    if (currentLocale !== defaultLocale) {
        if (url.includes(`/${currentLocale}/`)) {
            url = url.replace(`/${currentLocale}`, '');
        }

        if (typeof window !== 'undefined') {
            const { origin } = window.location;

            if (url.includes(origin)) {
                return `/${currentLocale}${url.replace(origin, '')}`;
            }

            return `/${currentLocale}${url}`;
        } else {
            return `/${currentLocale}${url}`;
        }
    }

    return url;
};

/** 获取可视化跳转页面 */
export const getVisualSkipPage = (row: any) => {
    const { type, web = {}, article, block, blocks, navPageID } = row || {};
    let url = '';

    switch (type) {
        case 'article':
            const { code, seo_url } = article || {};
            url = `/article/${seo_url || code}`;
            break;
        case 'web':
            url = web.url;
            break;
        case 'block':
            const { blockId: blockId } = block || {};
            url = `/block/${blockId}`;
            break;
        case 'blocks':
            const { blockIds } = blocks || {};
            url = `/block/${blockIds?.map((item: any) => item.block_id).join(',')}`;
            break;
        case 'page':
            url = `/page/${navPageID}`;
            break;
        default:
            break;
    }
    return url;
};

/** 将首字母小写 */
export const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toLowerCase() + str.slice(1);
};

/** 删除html字符串中的部分内容 */
export const removeHtmlContent = (content: string, classNames: string[]): string => {
    try {
        const $ = cheerioLoad(content);

        classNames.forEach((className) => {
            $(`.${className}`).each((_, element) => {
                $(element).empty(); // 清空元素内容
            });
        });

        return $.html() || '';
    } catch (error) {
        console.error('Error processing HTML:', error);
        return content;
    }
};
/** 判断路径是否是绝对路径 */
function isAbsoluteUrl(url: string) {
    try {
        // 尝试用 URL 构造函数创建一个新的 URL 对象
        const parsedUrl = new URL(url); // 基础 URL 是为了处理相对 URL
        // 检查是否有协议部分
        return parsedUrl.protocol === 'http:' || parsedUrl.protocol === 'https:' || parsedUrl.protocol === 'ftp:';
    } catch (e) {
        // 如果 URL 构造函数抛出错误，则说明 URL 不是有效的绝对路径
        return false;
    }
}

/** 设置url参数 */
export function addOrUpdateQueryParam(relativeUrl: string, param: string, value: string) {
    // 绝对路径
    if (isAbsoluteUrl(relativeUrl)) {
        const urlObj = new URL(relativeUrl);
        // 添加或更新参数
        urlObj.searchParams.set(param, value);

        // 返回更新后的相对路径
        return urlObj.origin + urlObj.pathname + urlObj.search;
    }

    // 使用相对路径创建一个 URL 对象
    const baseUrl = window.location.origin; // 使用一个基准 URL
    const urlObj = new URL(relativeUrl, baseUrl);
    // 添加或更新参数
    urlObj.searchParams.set(param, value);

    return urlObj.pathname + urlObj.search;
}

/**
 * 查询是否是json字符串
 * @param str
 * @returns
 */
export function isJsonString(str: string) {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }
}
