import request from '@/utils/axios';

/** 广告  */

/**
 * 获取广告详情
 * @returns
 */
export const getAdvertising = (params: { ids: string }, locale?: string) => {
    return request.get('/japi/advertising/management/get', params, { locale });
};

/** 广告用途 0-两侧 1-详情底部 2-详情正文 3-展示页面 */
export type AdvertisingPurposeType = '0' | '1' | '2' | '3';

/**
 * 获取广告详情列表
 * @returns
 */
export const getAllAdvertising = (
    data: {
        /** 广告用途 0-两侧 1-详情底部 2-详情正文 3-展示页面 */
        purpose?: AdvertisingPurposeType;
        /** 状态 */
        state?: string;
    },
    locale?: string,
) => {
    return request.post('/japi/advertising/management/all', data, { locale });
};
