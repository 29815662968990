import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useRouter } from 'next/router';
import { cloneDeep } from 'lodash';

import { breadcrumbsHierarchyMap } from '@/constants/breadcrumbs';
import {
    addBreadcrumbsList,
    addHrefEventListener,
    addWindowOpenEventListener,
    disposeBreadcrumbsName,
    getCurrentUrlQueryParam,
} from '@/utils/crumbs';
import { addOrUpdateQueryParam } from '@/utils';

const useBreadcrumbs = (pageProps: any) => {
    const router = useRouter();

    const { pathname, query } = router;
    const { formerCrumbsId } = query;
    const transitCrumbs = useRef<string>(''); // 中转用面包屑

    /** 当前页面层级 */
    const breadcrumbsHierarchy = useMemo(() => {
        return breadcrumbsHierarchyMap.findIndex((item) => {
            return item.find((subItem) => subItem.url === pathname);
        });
    }, [pathname]);

    /** 当前页面面包屑数据 */
    const currentBreadcrumbs = useMemo(() => {
        const { pathname, asPath } = router;

        if (breadcrumbsHierarchy < 0) {
            return [];
        }

        const result: any = cloneDeep(
            breadcrumbsHierarchyMap[breadcrumbsHierarchy]?.find((subItem) => subItem.url === pathname) || {},
        );

        result.url = asPath;
        result.grade = breadcrumbsHierarchy;

        return disposeBreadcrumbsName({ result, pageProps, router });
    }, [breadcrumbsHierarchy, router, pageProps]);

    /** 面包屑id */
    const crumbsId = useMemo(() => {
        if (query?.crumbsId) {
            return query?.crumbsId as string;
        }
        // 层级大于2的页面,并且不是从其他面包屑跳转过来的,不做新增面包屑
        if (breadcrumbsHierarchy > 2 && !formerCrumbsId) {
            return '';
        }
        const timestamp = Date.now().toString(36).slice(-3); // 获取当前时间戳的最后3位
        const randomPart = Math.random().toString(36).slice(2, 6); // 获取随机部分的4个字符
        return timestamp + randomPart;
    }, [query]);

    /** 更新页面参数 */
    const updateQuery = ({ add, delKey }: { add?: any; delKey?: string }) => {
        const { [delKey || '']: _, ...updatedQuery } = query;

        router.replace(
            {
                pathname: router.pathname,
                query: { ...updatedQuery, ...add },
            },
            undefined,
            { shallow: true },
        );
    };

    /** 没储存面包屑数据时的处理 */
    const noStoredBreadcrumbsHandler = useCallback(() => {
        // 层级大于2的页面,则直接删除页面上的参数,并且不做面包屑操作
        if (breadcrumbsHierarchy > 2) {
            updateQuery({ delKey: 'crumbsId' });
            return;
        }

        const newBreadcrumbs = currentBreadcrumbs.reduce(
            (init: any[], item: any) => {
                return addBreadcrumbsList(item, init);
            },
            [{ url: '/', grade: 0, name: '首页', mts: 'Crumbs.HomePage' }],
        );

        sessionStorage.setItem(crumbsId, JSON.stringify(newBreadcrumbs));

        // 添加页面参数记录面包屑
        updateQuery({ add: { crumbsId: crumbsId } });
    }, [breadcrumbsHierarchy, currentBreadcrumbs, crumbsId]);

    // 处理携带面包屑的跳转的内容
    useEffect(() => {
        if (formerCrumbsId) {
            // 获取存储的面包屑
            const storedBreadcrumbs = JSON.parse(sessionStorage.getItem(formerCrumbsId as string) || '[]');

            // 面包屑数据为空,不做新增操作参数
            if (storedBreadcrumbs.length === 0) {
                updateQuery({ delKey: 'formerCrumbsId' });
                return;
            }

            // 复制一份面包屑数据
            sessionStorage.setItem(crumbsId, JSON.stringify(storedBreadcrumbs));

            // 添加页面参数记录面包屑
            updateQuery({ add: { crumbsId: crumbsId }, delKey: 'formerCrumbsId' });
        }
    }, [crumbsId, formerCrumbsId]);

    // 处理面包屑ID
    useEffect(() => {
        if (!crumbsId) {
            return;
        }
        // 获取存储的面包屑
        const storedBreadcrumbs = JSON.parse(sessionStorage.getItem(crumbsId) || '[]');

        // 面包屑数据为空并且没有前面包屑id
        if (storedBreadcrumbs.length === 0 && !formerCrumbsId) {
            noStoredBreadcrumbsHandler();
            return;
        }

        // 添加新面包屑
        const newBreadcrumbs = currentBreadcrumbs.reduce((init: any[], item: any) => {
            return addBreadcrumbsList(item, init);
        }, storedBreadcrumbs);

        sessionStorage.setItem(crumbsId, JSON.stringify(newBreadcrumbs));
    }, [noStoredBreadcrumbsHandler, currentBreadcrumbs, formerCrumbsId, crumbsId]);

    /** 在新标签页中,读取之前存的sessionStorage并同步 */
    useEffect(() => {
        try {
            // 在新标签页中
            window.addEventListener('load', () => {
                const backup = window.localStorage.getItem('sessionStorageBackup');
                if (backup) {
                    const data = JSON.parse(backup);
                    for (const [key, value] of Object.entries(data)) {
                        sessionStorage.setItem(key, value as string);
                    }
                    // 清理备份
                    window.localStorage.removeItem('sessionStorageBackup');
                }
            });
        } catch {
            window.localStorage.removeItem('sessionStorageBackup');
        }
    }, []);

    // ------------------- 拦截跳转,增加面包屑id Start -------------------
    useEffect(() => {
        // 拦截window.open跳转
        addWindowOpenEventListener();
        // 拦截a标签跳转
        addHrefEventListener();
    }, []);

    const handleRouteChangeStart = (url: string) => {
        // 使用相对路径创建一个 URL 对象
        const currentCrumbsId = getCurrentUrlQueryParam('crumbsId');

        if (currentCrumbsId) {
            transitCrumbs.current = currentCrumbsId;
        }
    };

    // 拦截next/Link跳转,增加面包屑id
    const handleRouteChangeComplete = (url: string) => {
        // 使用相对路径创建一个 URL 对象
        const baseUrl = window.location.origin;
        const urlObj = new URL(url, baseUrl);
        // 添加或更新参数
        const crumbsId = urlObj.searchParams.get('crumbsId');
        const formerCrumbsId = urlObj.searchParams.get('formerCrumbsId');

        // 如果已经存在 formerCrumbsId 参数或者crumbsId参数，则不做处理。跳转前没有crumbsId参数
        if (!formerCrumbsId && !crumbsId && transitCrumbs.current) {
            const newUrl = addOrUpdateQueryParam(url, 'formerCrumbsId', transitCrumbs.current);

            if (newUrl !== url) {
                transitCrumbs.current = '';
                router.replace(newUrl, undefined, { shallow: true }).catch(console.error);
                return newUrl;
            }
            return url;
        }
    };

    useEffect(() => {
        router.events.on('routeChangeStart', handleRouteChangeStart);
        router.events.on('routeChangeComplete', handleRouteChangeComplete);
        return () => {
            router.events.off('routeChangeStart', handleRouteChangeStart);
            router.events.off('routeChangeComplete', handleRouteChangeComplete);
        };
    }, [router.events, router.locale]);
    // ------------------- 拦截跳转,增加面包屑id End -------------------
};

export default useBreadcrumbs;
