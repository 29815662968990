import type { AdvertisingDetailsType } from '@/type/advertising';
import { isJsonString } from './util';
import intl from 'react-intl-universal';
import { apiLangHeaderMap, defaultLocale } from '@/constants/mts';

/** 转换adList数据 */
const transitionAdList = (list: any[]) => {
    if (!list || list.length === 0) return [];
    const currentLocale: string = intl.getInitOptions().currentLocale || defaultLocale;

    return list.map((item) => {
        const { titleMts, title, coverMts, openInfo, startEffectiveTime, endEffectiveTime } = item;

        // 设置时间区域
        let time;
        if (startEffectiveTime && endEffectiveTime) {
            time = [startEffectiveTime, endEffectiveTime];
        }

        // 转换封面多语言
        const newCoverMts = isJsonString(coverMts) ? JSON.parse(coverMts) : coverMts || {};

        return {
            title,
            titleMts: isJsonString(titleMts) ? JSON.parse(titleMts) : titleMts,
            openInfo: isJsonString(openInfo) ? JSON.parse(openInfo) : openInfo,
            coverMts: newCoverMts,
            cover: newCoverMts[apiLangHeaderMap[currentLocale]] || '',
            time,
        };
    });
};

/** 转换后台广告数据，用于适配旧格式 */
export const transitionAdvertiseData = (data: AdvertisingDetailsType) => {
    const { type, adCode, canClose, state, adList = [], width = 0, height = 0 } = data || {};

    // 设置默认样式
    const customStyle = {
        width,
        height,
        widthUnit: '%',
        heightUnit: 'px',
        paddingTop: 16,
    };

    // 如果被禁用则不做展示
    if (state !== '0') {
        return {};
    }

    switch (type) {
        case '0':
            return {
                styleType: '2',
                canClose: canClose === '0',
                adList: transitionAdList(adList),
                customStyle,
            };
        case '1':
            return {
                styleType: '1',
                adCode,
                customStyle,
            };
        default:
            return {};
    }
};
